import { axios, merchantId } from '@/service/config'
//from custom function
export const GetBreadCrumbByProductId = (productId) => {
    return axios.request({
        url: 'api/Category/GetBreadCrumbByProductId?merchantId=' + merchantId + '&productId=' + productId,
        method: 'GET',
        crossdomain: true
    })
}
export const GetBreadCrumbByProdCateId = (prodCateId) => {
    return axios.request({
        url: 'api/Category/GetBreadCrumbByProdCateId?merchantId=' + merchantId + '&prodCateId=' + prodCateId,
        method: 'GET',
        crossdomain: true
    })
}
export const CheckIsProductCanBuy = (productSpecId, qvt) => {
    return axios.request({
        url: 'api/Order/CheckIsProductCanBuy?merchantId=' + merchantId + '&productSpecId=' + productSpecId + "&qvt=" + qvt,
        method: 'GET',
        crossdomain: true
    })
}
export const GetRate = () => {
    return axios.request({
        url: "/api/Rate/GetAllRate",
        type: 'POST',
        dataType: "json",
        async: false,
        data:
        {
            "sToken": "DDlnHbZAA6KGp0Y4LGz9"
        },
        crossdomain: true
    })
}
//from_index
export const GetActiveBanners = () => {
    return axios.request({
        url: '/api/Product/GetActiveBanners?merchantId=' + merchantId,
        method: 'GET',
        crossdomain: true,
    })
}

// export const GetCard = () => {
//     return axios.request({
//         url: '/api/Category/GetCard?merchantId=' + merchantId ,
//         method: 'GET'
//     })
// }

//from masterpage
export const GetAllCategory = () => {
    return axios.request({
        url: '/api/Category/GetAllCategory?merchantId=' + merchantId,
        method: 'GET',
        crossdomain: true,
    })
}
export const GetAllCategoryWithChild = () => {
    return axios.request({
        url: '/api/Category/GetAllCategoryWithChild?merchantId=' + merchantId,
        method: 'GET',
        crossdomain: true,
    })
}
//from category
export const GetCategoryWithChildByProdCateId = (prodCateId) => {
    return axios.request({
        url: '/api/Category/GetCategoryWithChildByProdCateId?merchantId=' + merchantId + '&prodCateId=' + prodCateId,
        method: 'GET',
        crossdomain: true,
    })
}
export const GetProdCateDetail = (prodCateId) => {
    return axios.request({
        url: '/api/Category/GetProdCateDetail?merchantId=' + merchantId + '&prodCateId=' + prodCateId,
        method: 'GET',
        crossdomain: true,
    })
}

export const GetProductCountByProdCateId = (prodCateId) => {
    return axios.request({
        url: '/api/Product/GetProductCountByProdCateId?merchantId=' + merchantId + '&prodCateId=' + prodCateId,
        method: 'GET',
        crossdomain: true,
    })
}

export const GetProductByProdCateId = (prodCateId, page, size, order) => {
    return axios.request({
        url: '/api/Product/GetProductByProdCateId?merchantId=' + merchantId + '&prodCateId=' + prodCateId + '&page=' + page + '&size=' + size + '&order=' + order,
        method: 'GET',
        crossdomain: true,
    })
}
//from product
export const GetHomeProduct = (page = 0, size = 12) => {
    return axios.request({
        url: '/api/Product/GetHomeProducts?merchantId=' + merchantId + '&page=' + page + '&size=' + size,
        method: 'GET',
        crossdomain: true,
    })
}
export const GetProductById = (productId) => {
    return axios.request({
        url: '/api/Product/GetProductById?merchantId=' + merchantId + '&productId=' + productId,
        method: 'GET',
        crossdomain: true,
    })
}

export const GetProductSpecByProdId = (productId) => {
    return axios.request({
        url: '/api/Product/GetProductSpecByProdId?merchantId=' + merchantId + '&productId=' + productId,
        method: 'GET',
        crossdomain: true,
    })
}

export const GetProductSpecByProdSpecId = (productSpecId) => {
    return axios.request({
        url: '/api/Product/GetProductSpecByProdSpecId?merchantId=' + merchantId + '&productSpecId=' + productSpecId,
        method: 'GET',
        crossdomain: true,
    })
}
export const GetRandomProduct = () => {
    return axios.request({
        url: '/api/Product/GetRandomProduct?merchantId=' + merchantId,
        method: 'GET',
        crossdomain: true,
    })
}
// from cart
export const GetTownList = (lastLevelId) => {
    return axios.request({
        url: '/api/Order/GetTownList?lastLevelId=' + lastLevelId,
        method: 'GET',
        crossdomain: true,
    })
}

export const PlaceOrder = (orderjson) => {
    return axios.request({
        url: '/api/Order/PlaceOrder?merchantId=' + merchantId,
        method: 'POST',
        data: orderjson,
        crossdomain: true,
        contentType: "application/json",
    })
}
//from search
export const GetProductCountByTitle = (querystring) => {
    return axios.request({
        url: '/api/Product/GetProductCountByTitle?merchantId=' + merchantId + '&querystring=' + querystring,
        method: 'GET',
        crossdomain: true,
    })
}

export const GetProductByTitle = (querystring, page, size, order) => {
    return axios.request({
        url: '/api/Product/GetProductByTitle?merchantId=' + merchantId + '&querystring=' + querystring + '&page=' + page + '&size=' + size,
        method: 'GET',
        crossdomain: true,
    })
}

//from order

export const GetOrderList = (phoneNo) => {
    return axios.request({
        url: '/api/Order/GetOrderList?merchantId=' + merchantId + '&phoneNo=' + phoneNo,
        method: 'GET',
        crossdomain: true,
    })
}

export const GetOrderByOrderId = (phoneNo, orderId) => {
    return axios.request({
        url: '/api/Order/GetOrderByOrderId?merchantId=' + merchantId + '&phoneNo=' + phoneNo + '&orderId=' + orderId,
        method: 'GET',
        crossdomain: true,
    })
}

// Login / Registration

export const Login = (loginViewModel) => {
    return axios.request({
        url: '/api/Account/Login',
        method: 'POST',
        data: loginViewModel,
        crossdomain: true,
        contentType: "application/json",
    })
}

export const Register = (registerViewModel) => {
    return axios.request({
        url: '/api/Account/Register',
        method: 'POST',
        data: registerViewModel,
        crossdomain: true,
        contentType: "application/json",
    })
}

export const SendRegisterValidationCode = (sendRegisterValidationViewModel) => {
    return axios.request({
        url: '/api/Account/SendRegisterValidationCode',
        method: 'POST',
        data: sendRegisterValidationViewModel,
        crossdomain: true,
        contentType: "application/json",
    })
}